.body{
    width: 100%;
    height: 60vh;
    display: grid;
    place-items: center;
}

.image-slider{
    border: 1px solid black;
    width: 97%;
    height: 400px;
    background-image: url("../assets/img/abt.jpg");
    background-size: 100% 100%;
    animation: changeImage 25s linear infinite;
}

@keyframes changeImage {
    0% {
        background-image: url("../assets/img/hero-bg.jpg");
    }
    25% {
        background-image: url("../assets/img/hero-bg-3.jpg");
    }
    50% {
        background-image: url("../assets/img/a.jpg");
    }
    75% {
        background-image: url("../assets/img/hero-bg-2.jpg");
    }
    100% {
        background-image: url("../assets/img/abt.jpg");
    }
}
@media(max-width: 480px) {
    .body{
        width: 100%;
        height: 50vh;
        display: grid;
        place-items: center;
    }
    .image-slider{
        width: 90%;
        height:300px ;

    }
    #sur-container {
        min-width: 100%;
    }
}
.title-cart{
    font-size: 19px;
    font-weight: bolder;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -50px;
}
.sections{
    display: flex;
    justify-content: center;
    height: 150vh;
    align-items: center;
}
.cart{
    display: flex;
    margin: 10px;
}
.card{
    height: 300px;
    margin: 10px;
    /* box-shadow: 5px 5px 20px black; */
    overflow: hidden;
    cursor: pointer;
}

.img{
    height: 200px;
    width: 300px;
    border-radius: 3px;
}

.intro{
    height: 50px;
    width: 140px;
    padding: 11px;
    text-align: center;
    box-sizing: border-box;
    position: absolute;
    background-color: transparent;
    color: white;
    bottom: 50px;
    margin-left: 70px;
}

.p{
    visibility: hidden;
    font-size: 19px;
    font-weight: bolder;
    padding-bottom: 10px;
    height: 35px;
}

.card:hover .intro{
    border: 1px solid white;
    height: 60px;
    bottom: 130px;
    background: #f79500;
    border-radius: 3px;
}

.card:hover .p{
    opacity: 1;
    visibility: visible;
    
}
.p:hover{
    /* background-color: white; */
    color: white;
}
@media(max-width: 480px) {
    .sections{
        display: flex;
        justify-content: center;
        height: 185vh;
        align-items: center;
        flex-direction: column;
    }
    .cart{
        display: flex;
        margin: 10px;
        flex-direction: column;
        margin-top: 140px;
    }
    .card{
        cursor: pointer;
    }
    
    .img{
        height: 200px;
        width: 300px;
        border-radius: 3px;
    }
    
    .intro{
        height: 50px;
        width: 140px;
        padding: 11px;
        text-align: center;
        box-sizing: border-box;
        position: absolute;
        background-color: transparent;
        color: white;
        bottom: 50px;
        margin-left: 70px;
    }
    
    .p{
        visibility: hidden;
        font-size: 19px;
        font-weight: bolder;
        padding-bottom: 10px;
        height: 35px;
    }
    .item-name{ 
        margin: 25px
    }
    
}

.item-name{ 
    position: absolute;
    margin-top: 200px;
    text-align: center;
    padding: 5px;
    margin-left: 70px;
}
.item-tag{
    font-size: 17px;
    font-weight: bolder;
    cursor: pointer;
}
.item-tag:hover{
    color: #f79500;
}
.checked {
    color: orange;
  }
/* .simpleslider{
    overflow: hidden;
    border: 1px solid red;
} */
/* @media only screen and (max-width: 300px) {
    .simpleslider{
        width: 100%;
        height: 450px;
        overflow: hidden;
        border: 1px solid red;
    }
} */

#survey-container {
    margin: 20px auto;
    padding: 30px 20px;
    width: 100%;
    max-width: 840px;
    border-radius: calc(3 * var(--border-radius));
    background-image: url("../assets/img/apply.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}
#sur-container {
    margin: 20px auto;
    padding: 30px 20px;
    width: 100%;
    max-width: 840px;
    border-radius: calc(3 * var(--border-radius));
    background-image: url("../assets/img/apply2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -75px;
}
  
  #title {
    margin: 30px 0;
    text-align: center;
    font-family: "Kumbh Sans", sans-serif;
    font-size: 50px;
    text-transform: capitalize;
    color: var(--color-3);
  }
  
  #description {
    margin: 30px 0;
    padding: 5px;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: var(--color-3);
  }
  
  #survey-form {
    padding: 30px;
    padding-top: 20px;
    background: var(--color-1);
    opacity: 98%;
    border: none;
    border-radius: calc(2 * var(--border-radius));
  }
  
  #survey-form * {
    font-size: var(--font-size);
  }
  
  .row-label,
  .row-input {
    padding: 5px 10px;
    margin: 0;
    height: var(--box-height);
    width: 100%;
    display: block;
  }
  
  .row-label {
    color: var(--color-3);
    font-weight: 600;
  }
  
  .row-label:not(:first-child) {
    margin-top: var(--space-between);
  }
  
  .row-input:not(.small) {
    background: white;
    border: none;
    border-radius: var(--border-radius);
  }
  
  .inline-label {
    margin-left: 10px;
    font-size: 14px;
    color: var(--color-3);
  }
  
  .small {
    height: calc(var(--box-height) * 0.75);
  }
  
  #comments {
    padding: 10px;
    margin: 0;
    height: 120px;
    width: 100%;
    background: white;
    border: none;
    border-radius: var(--border-radius);
    resize: none;
  }
  
  #submit {
    margin-top: calc(2 * var(--space-between));
    height: var(--box-height);
    width: 100%;
    background: var(--color-4);
    border: none;
    border-radius: var(--border-radius);
    color: white;
    font-weight: 600;
    cursor: pointer;
    text-transform: capitalize;
  }
  
  #submit:hover {
    box-shadow: 0 0 2px var(--color-3);
  }
  .cup-banner-img{
    animation: rotateBox 4s infinite linear;
    width: 20%;
    height: 290px;
    float: inline-end;
  }
  @keyframes rotateBox {
    0% {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    25% {
      transform: rotateX(180deg) rotateY(180deg) rotateZ(180deg);
    }
  }