  h1{
      font-family: Satisfy;
      font-size:50px;
      text-align:center;
      color:#fff;
      padding:1%;
    }
    #gallery{
      /* padding: 4%; */
      text-align: center;
      -webkit-column-count:4;
      -moz-column-count:4;
      column-count:3;
      
      -webkit-column-gap:20px;
      -moz-column-gap:20px;
      column-gap:20px;
    }
    @media (max-width:1200px){
      #gallery{
      -webkit-column-count:3;
      -moz-column-count:3;
      column-count:3;
        
      -webkit-column-gap:20px;
      -moz-column-gap:20px;
      column-gap:20px;
    }
    }
    @media (max-width:800px){
      #gallery{
      -webkit-column-count:2;
      -moz-column-count:2;
      column-count:2;
        
      -webkit-column-gap:20px;
      -moz-column-gap:20px;
      column-gap:20px;
    }
    }
    @media (max-width:600px){
      #gallery{
      -webkit-column-count:1;
      -moz-column-count:1;
      column-count:1;
    }  
    
    .border-bg2{
      display: none;
    }
    }
    #gallery img,#gallery video {
      width:100%;
      height:auto;
      margin: 4% auto;
      box-shadow:-3px 5px 15px #000;
      cursor: pointer;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
    }
    .modal-img,.model-vid{
      width:100%;
      height:auto;
    }
    .modal-body{
      padding:0px;
    }
    .border-bg{
      background-image: url("../assets/img/2.png");
      background-size: cover;
      margin-top: -35px;
      opacity: 0.9;
      height: 50px;
      width: 100%;
    }
    .border-bg2{
      background-image: url("../assets/img/2.png");
      background-size: cover;
    margin-bottom: -48px;
    /* margin-top: 0px; */
    background-position-y: bottom;
    opacity: 0.9;
    height: 25px;
    width: 100%;
    }

    .responsive{
      width: 80% !important;
    }