/* Franchise Page */

ul{
    margin:0;
    padding:0;
  }
  .button_1{
    height:38px;
    background: brown;
    border:0;
    padding-left: 20px;
    padding-right:20px;
    color:#ffffff;
  }
  .dark{
    padding:15px;
    background:#f79500;;
    color:#ffffff;
    margin-top:120px;
    margin-bottom:10px;
    border-top-right-radius: 30%;
    border-bottom-left-radius: 30%;
  }
  /* Header **/
  header{
    background-color: #f79500;
    color:#ffffff;
    padding-top:30px;
    min-height:70px;
    border-bottom:#e8491d 3px solid;
  }
  header a{
    color:#9f4800;
    text-decoration:none;
    text-transform: uppercase;
    font-size:16px;
  }
  header li{
    float:left;
    display:inline;
    padding: 0 20px 0 20px;
  }
  header #branding{
    float:left;
  }
  header #branding h1
  {
    margin:0;
  }
  header nav{
    float:right;
    margin-top:10px;
  }
  header .highlight, header .current a
  {
    color:#ffb200;
    font-weight:bold;
  }
  header a:hover{
    color:#cccccc;
    font-weight:bold;
  }
  /* Showcase */
  #showcase{
    min-height:400px;
    background:url('../assets/img/bg-menu.png') no-repeat;
    background-position: center;
    background-size: cover;
    text-align:center;
    color:#ffffff;
  }
  #showcase h1{
    margin-top:100px;
    font-size:55px;
    margin-bottom:10px;
  }
  #showcase p{
    font-size:20px;
  }
  /* Newsletter */
  #newsletter{
    padding: 15px;
    color: beige;
    height: fit-content;
    margin-top: -20px;
    border: 1px solid wheat;
    width: 100%;
    /* background-image: url("https://img.freepik.com/free-photo/stained-white-background_23-2147924672.jpg?w=740&t=st=1698221253~exp=1698221853~hmac=704eb41a8d40314d71d7144fb996704357d6a1f794614c342889dc363f1be714"); */
   background-image: url("../assets/img/wp5586623.png");
   background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  #newsletter h1{
    float:left;
  }
  #newsletter form {
    float:right;
    margin-top:40px;
  }
  #newsletter input[type="email"]{
    padding:4px;
    height:40px;
    width:250px;
  }
  /* Boxes */
  #boxes{
    margin-top:20px;
  }
  #boxes .box{
    float:left;
    text-align: center;
    width:30%;
    padding:10px;
  }
  #boxes .box img{
    width:90px;
  }
  /* Sidebar */
  aside#sidebar{
    float:right;
    width:30%;
    margin-top:10px;     
  }
  aside#sidebar .quote input, aside#sidebar .quote textarea{
    width:90%;
    padding:5px;
    color: black;
  }
  /* Main-col */
  article#main-col{
    float:left;
    width:65%;
  }
  /* Services */
  ul#services li{
    list-style: none;
    padding:20px;
    border: #cccccc solid 1px;
    margin-bottom:5px;
    background:#e6e6e6;
  }
  footer{
    padding:20px;
    margin-top:20px;
    color:#ffffff;
    background-color:#f79500;
    text-align: center;
  }
  @media(max-width: 768px){
    header #branding,
    header nav,
    header nav li,
    #newsletter h1,
    #newsletter form,
    #boxes .box,
    article#main-col,
    aside#sidebar{
      float:none;
      text-align:center;
      width:100%;
    }
    header{
      padding-bottom:20px;
    }
    #showcase h1{
      margin-top:40px;
    }
    #newsletter button, .quote button{
      display:block;
      width: 88%;
      margin-left: 15px;
    }
    #newsletter form input[type="email"], .quote input, .quote textarea{
      width:100%;
      margin-bottom:5px;
    }
    .dark{
        padding:15px;
        background:#f79500;;
        color:#ffffff;
        margin-top:15px;
        margin-bottom:-15px;
        border-top-right-radius: 30%;
        border-bottom-left-radius: 30%;
      }
      /* .opportunity{
        
        font-size: 16px;
        font-weight: 500;
        width: 90%;
      } */
      .franchise-title{
        /* border: 1px solid red; */
        width: 100%;
        color:black;
        font-size: 26px;
      }
      .francise-head{
        /* border: 1px solid red; */
        top: -130px;
        color: white;
      }
      /* .Ftitle{
        border: 1px solid red;
      } */
  }
  .franchise{
    /* border: 1px solid red; */
    padding: 10px;
    background-image: url("../assets/img/bg-menu.png");
  }
  .francise-head{
    /* border: 1px solid red; */
    text-align: center;
    width: 100%;
    color: black;
    position: relative;
    justify-content: center;
    display: flex;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .opportunity{
    /* border: 1px solid white; */
    font-size: 17px;
    font-weight: 500;
    /* width: 98%; */
    /* font-family: math; */
  font-family: sans-serif;
    text-align: justify;
    color: black;
    line-height: 1.5;
  }
  
  .franchise-title{
    /* border: 1px solid red; */
    /* width: 100%; */
    color:black;
    /* font-size: 26px;
    height: 100px; */
    font-family: math;
    font-weight: 600; 
  }
  .Ftitle{
    /* border: 1px solid red; */
    margin-top: 8%;
    margin-bottom: 8%;
    /* margin-left: 8%;
    margin-right: 8%; */
    float: inline-end;
  }
  .Ftitle img{
    padding: 10%;
  }
  .accordion{
    border: 1px solid #f8f5f5;
    background-color: #f1eded;
  }
  .accordion-content{
    background-color: #fff;
  }



  
  .card {
    box-sizing: border-box;
    height: 200px;
    overflow: hidden;
    transition: 0.5s;
    /*    */
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  }
  
  .card:hover {
    height: 400px;
  }
  
  .card__top {
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    color: #eee;
    align-items: center;
    height: 200px;
    width: 300px;
    background: #434343;
    transition: 0.8s;
  }
  
  .card__top > h1 {
    font-size: 24px;
  }
  
  .fas {
    font-size: 50px;
    text-align: center;
  }
  
  .card:hover > .card__top {
    background: #eee;
    color: #f79500;
  }
  
  .card__bottom {
    box-sizing: border-box;
    height: 200px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    padding: 16px;
    background: #f79500;
  }
  
  .card__bottom > p {
    color: #eee;
    font-size: 16px;
    line-height: 1.3em;
  }
  
  .card__bottom a {
    background: #fff;
    color: #f107ba;
    display: inline-block;
    text-decoration: none;
    padding: 8px 16px;
    transition: 0.5s;
  }
  
  .card__bottom a:hover {
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
  