#our{ 
    /* background-color:#EFEFEF; */
    /* height: 1110px; */
    height: 100%;
    top: -20px;
    /* padding-top:20px; */
    padding-bottom:30px;
    margin-bottom:10px;
    background-image: url("../assets/img/menu2.jpg");
    /* background-image: url("../assets/img/top-view-elegant-plate-with-copy-space.jpg"); */
      /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover; */
  background-size: cover;
  background-position: center;
  position: relative;

   }
   #our::before{
    content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
   }


   .menu-bg{    
    background-image: url("../assets/img/menu-bggg.jpg");
    /* border: 1px solid red; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 300px;
    position: relative;
    margin-top: -20px;
  }
    .our_1 h2 {
    color: white;
    font-family: "Segoe Script";
    font-size: 38px;
    line-height: 1.3;
    /* border-radius: 50px 20px;
    border: 1px solid #f79500;
    height: 60px;
       width: 400px; */
    }
    /* .our_1 .text-center:hover{
       border: 3px solid #f79500; 
       height: 60px;
       width: 400px;
       margin-left: 300px;

    } */
    .our_1 p {
    padding: 0;
    margin: 0;
    color: #949494;
    font-size: 19px;
    line-height: 1.5;
    padding-bottom: 80px;
    }
    #our .ih-item {
    position: relative;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    }
    #our .ih-item,
    #our .ih-item * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    }
    #our .ih-item a {
    color: #333;
    }
    #our .ih-item a:hover {
    text-decoration: none;
    }
    #our .ih-item img {
    width: 100%;
    height: 100%;
    }
    
    #our .ih-item.square {
    position: relative;
    width: 100%;
    height: 100%;
    /* width: 255px;
    height: 280px; */
    border: 5px solid #fff;
    border-radius: 10%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    }
    #our .ih-item.square .info {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    }
    
    #our .ih-item.square.effect13 {
    overflow: hidden;
    }
    #our .ih-item.square.effect13.colored .info {
    background: #1a4a72;
    background: rgba(26, 74, 114, 0.6);
    }
    #our .ih-item.square.effect13.colored .info h3 {
    background: rgba(12, 34, 52, 0.6);
    }
    #our .ih-item.square.effect13 .img {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    }
    #our .ih-item.square.effect13 .info {
    background: #333333;
    background: rgba(0, 0, 0, 0.6);
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
    }
    #our .ih-item.square.effect13 .info h3 {
    font-family: "Lucida Handwriting", cursive;
    color: #fff;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    font-weight:600;
    margin-top: 45%;
    margin-bottom: 45%;
    /* background: #111111; */
    /* margin: 105px 10px; */
    font-family: "Franklin Ghotics Medium", Times, serif;
    }
    #our .ih-item.square.effect13 .info p {
    /* font-style: italic; */
    font-size: 12px;
    position: relative;
    color: #bbb;
    padding: 20px 20px 20px;
    text-align: center;
    }
    #our .ih-item.square.effect13 a:hover .img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    }
    #our .ih-item.square.effect13 a:hover .info {
    visibility: visible;
    opacity: 1;
    }
    
    #our .ih-item.square.effect13.left_to_right .info {
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    }
    #our .ih-item.square.effect13.left_to_right a:hover .info {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    }
    .our_3 h4{ 
    padding:0;
    margin:0;
    padding-top:15px;
    padding-bottom:20px;
    }
    .our_3 h4 a{ 
    color: white;
    text-decoration:none;
    font-size: 24px;
    line-height: 1;
    font-weight:bold;
    /* font-family: "Lucida Handwriting"; */
    font-family:  "Mongolian Baliti", Times, serif;


    }
    .our_3 h4 a:hover{ 
    color: #f79500;
    }
    .our_3 p{ 
    color: #949494;
    font-size: 19px;
    line-height: 1.5;
    }
    @media screen and (max-width : 767px){
        #our {
          height: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-bottom: 10px;
            text-align: center;
            }
            #our .ih-item.square {
            width: 100%;
            height: 100%;
            }
            .our_3 h4 {
                padding: 0;
                margin: 0;
                padding-top: 15px;
                padding-bottom: 20px;
            }
            .our_2 {
                /* margin-left: 0px; */
                text-align: -webkit-center;
            }            
            
            #our .ih-item.square {
                position: relative;
                /* width: 285px;
                height: 280px; */
                width: 100%;
                height: 50%;
                border: 5px solid #fff;
                border-radius: 10%;
                box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
            }
            .item-desc{
              /* border: 1px solid white; */
              color: white;
              font-size: 16px;
              font-weight: 600;
              width: 90%;
              text-align: center;
              padding: 10px;
              transform: scale(0.94);
              font-family: "nunito sans";
              margin-right: auto;
              margin-left: auto;
              /* animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1); */
              /* margin-left: 60px; */
              /* height: 100px; */
              margin-top: -20px;
          }
           
    }
    /* .our_1 h2::after{
        width: 100%;
        content: "";
        left: 0;
        bottom: 0;
        position: absolute;
        background: transparent;
        height: 3px;
    } */
    /* .our_1 h2:hover{
        border: 1px solid red;
    } */


    /* .breadcrumb-text b {
        animation: move 3s;
        -webkit-animation: move 3s;
      }
      
      @keyframes move {
        from {
          margin-left: 100%;
          width: 300%; 
        }
      
        to {
          margin-left: 0%;
          width: 100%;
        }
      }
                  
      @-webkit-keyframes move {
        from {
          margin-left: 100%;
          width: 300%; 
        }
      
        to {
          margin-left: 0%;
          width: 100%;
        }
      } */
.item-desc{
    /* border: 1px solid white; */
    color: white;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    padding: 10px;
    transform: scale(0.94);
    font-family: "nunito sans";
    margin-right: auto;
    margin-left: auto;
    /* animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1); */
    /* margin-left: 60px; */
    /* height: 100px; */
    margin-top: -26px;
}

  @keyframes scale {
    100% {
      transform: scale(1);
    }
  }

#career{
    /* border: 1px solid red; */
    background-image: url("https://www.globalcareercounsellor.com/blog/wp-content/uploads/2023/06/Best-5-Tips-on-Career-Development.jpg");
    background-size: cover;
    background-position: center;
    margin-top: -20px;
    margin-bottom: -20px;
}
#career::before{
    /* content: ""; */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
   }


   /* defines the animation */
@keyframes fadeInUp {
    from { 
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  .item-desc {
    opacity: 0; 
    animation: fadeInUp 1s ease-in-out 0s forwards;
  
    &.first {
      animation-delay: 1s;
    }
    &.second {
      animation-delay: 3s;
    }
    &.third {
      animation-delay: 5s;
    }
    &.fourth {
      animation-delay: 7s;
    }
   
   }
  
   .word {
    display: block;
  
    animation: show 0.01s forwards, pop-word 1.5s forwards;
    animation-timing-function: cubic-bezier(0.14, 1.23, 0.33, 1.16);
    opacity: 0;
  
    transform: rotateX(120deg);
    transform-origin: 50% 100%;
  }
  
  .word:nth-of-type(2) {
    padding: 0 2rem;
  
    animation-delay: 1.5s;
  
    color: gold;
  }
  @keyframes pop-word {
    to {
      transform: rotateX(0);
    }
  }
  
  @keyframes show {
    to {
      opacity: 1;
    }
  }
  
  @keyframes bar-scale {
    to {
      transform: scaleY(1);
    }
  }
  
  @keyframes sparkle {
    0% {
      transform: scale(0);
    }
  
    60% {
      transform: scale(1) translate(4px, 1px) rotate(8deg);
    }
  
    100% {
      transform: scale(0) translate(4px, 1px) rotate(8deg);
    }
  }
  
  @keyframes shimmer {
    to {
      text-shadow: 0 0 8px red;
    }
  }
  